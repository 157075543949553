/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { ReactNode } from "react";
import styles from "./styles.module.scss";

interface PageLayoutProps {
  header?: ReactNode;
  children: ReactNode;
}

export const PageLayout = ({ header, children }: PageLayoutProps) => (
  <>
    {
      // TODO: turn into breadcrum?
      header && (
        <header className={styles.header}>
          <div>{header}</div>
        </header>
      )
    }
    <main className={styles.body}>
      <div>{children}</div>
    </main>
  </>
);
