/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";

export const Footer = () => (
  <footer className={styles.footer}>
    <div>
      <ul>
        <li>
          <NavLink to="/privacy" role="link">
            PRIVACY STATEMENT
          </NavLink>
        </li>
      </ul>
    </div>
  </footer>
);
