/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

import { ChangeEventHandler } from "react";
import styles from "./help.module.scss";

type InputBoxProps = {
  id: string;
  label: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: string;
};
export function QueryInputBox({ id, label, value, onChange, type = "text" }: InputBoxProps) {
  return (
    <div className={styles.formItem}>
      <label className={styles.formLabel} htmlFor={id}>
        {label}
      </label>
      <input
        type={type}
        className={`form-control ${styles.formControl}`}
        id={id}
        role={id} // for testing
        value={value}
        onChange={onChange}
        placeholder={`Enter Your ${label}`}
        required
      />
    </div>
  );
}
